<template>
  <div class="container">
		<div class="row">
			<div class="col-12">
				<div class="sign__content">
          <form @submit.prevent="unsubscribe()" class="sign__form">
            <a href="index.php" class="sign__logo">
              <img src="assets/img/logoSvg.svg" alt="">
					  </a>
						<p class="arabickufi" style="font-size:  25px; font-weight: bold; "> إلغاء الإشتراك  </p>
            <!-- <p class="sub-title mb-3 text-center zoal arabickufi" style="font-size:  16px; width:80%; font-weight: bold; inherit;"> هل انت متأكد من انك تريد إلغاء الإشتراك من الخدمة ؟ </p> -->
            <div class="sign__group">
              <button class="sign__btn button">
                <span v-if="loading" class="px-1 arabickufi" style="color: #fff;">الغاء الاشتراك</span>  &nbsp;
                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                <span v-else class="arabickufi" style="color: #fff;">الغاء الاشتراك</span>
              </button>
              <span class="sign__delimiter"><h6></h6></span>
						</div>
					</form>
          <!-- <button class="sign__btn" @click="goBack">رجـــــوع</button> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { useToast } from "vue-toastification";
export default {
   name: 'AppUnsubscribes',
   data() {
     return {
       loading: false,
     };
   },
    mounted() {
       if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
          this.$router.push({ path: "/" })
       }
    },
   methods: {
      goBack() {
         return this.$router.go(-1)
      },
      async unsubscribe () {
        const toast = useToast();
          let button = document.querySelector(".button");
          button.disabled = true;
          this.loading = true;
        await HTTPDSP.post("DSPUnsubscribe.php?msisdn="+this.$cookie.getCookie("msisdn")).then((response) => {
          if (response.data.status == 0) {
            toast.error("انت لست مشترك في منصة عيون الشعر", { timeout: 2000 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          } else {
            toast.success("تم الغاء اشتراكك من منصة عيون الشعر ", { timeout: 2000 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style>

</style>